"use client";
import styles from "./index.module.scss";
import Image from "next/image";
import { IAllStories } from "@/services/types/types";

interface IStoriesElem {
  checked: boolean;
  onClick: () => void;
  story: IAllStories;
}

const StoriesElem = (props: IStoriesElem) => {
  
  const {
    checked,
    onClick,
    story
  } = props;
  
  return (
    <div
      className={`${styles.stories_item} ${
        checked ? styles.is_checked : ""
      }`}
      onClick={onClick}
    >
      <div className={styles.image_container}>
        <Image
          className={styles.image}
          alt={story.name}
          src={`https://ohotaktiv.ru${story.picture}`}
          width={400}
          height={400}
        />
      </div>
      <p className={styles.stories_text}>{story.name}</p>
    </div>
  );
};

export default StoriesElem;