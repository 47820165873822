import { FC, useRef } from "react";
import styles from "./input-uploader.module.scss";
import Image from "next/image";
import { CloseIcon } from "../icon";
import { callMessage } from "@/utils/functions/callMessage";
import { deleteImageAnnouncement } from "@/utils/api/postAnnouncement";

interface IInputUploader {
  imagesArray: any;
  setImagesArray: any;
  value?: any;
  onChange?: any;
}

export const InputUploader: FC<IInputUploader> = ({
  imagesArray,
  setImagesArray,
  value,
  onChange,
}) => {
  const inputRef = useRef(null) as any;
  const handleChange = () => {
    Object.values(inputRef.current.files).forEach((el: any) => {
      const checkDoublicat = imagesArray.find(
        (elem: any) => elem.name === el.name
      );
      if (el.name === checkDoublicat?.name) {
        return callMessage(
          "Ошибка!",
          "Вы выбрали одинаковые фотографии.",
          false
        );
      }
      setImagesArray((prevArray: any) => [...prevArray, el]);
    });
  };
  const handleRemoveImage = (image: any, index: Number) => {
    if (image.value) {
      deleteImageAnnouncement(image.value)
        .then((res: any) => {
          if (!res.success) return;
          setImagesArray(imagesArray.filter((img: any, i: number) => i !== index));
          const fileListArr = Array.from(inputRef.current.files);
          fileListArr.splice(Number(index), 1);
        });
      return;
    }
    setImagesArray(imagesArray.filter((img: any, i: any) => i !== index));
    const fileListArr = Array.from(inputRef.current.files);
    fileListArr.splice(Number(index), 1);
  };
  
  return (
    <div className={styles.uploader}>
      <div className={styles.uploaderWrap}>
        <div className={styles.uploaderOutput}>
          {imagesArray.length > 0 &&
            imagesArray.map((image: any, index: Number) => {
              return (
                <div
                  key={`${index + "divImagesArray" + "inputhUpLoader"}`}
                  className={styles.uploaderOutputImageWrap}
                >
                  <Image
                    className={styles.uploaderOutputImage}
                    src={image.value ? `http://ohotaktiv.ru${image.value}` : URL.createObjectURL(image)}
                    alt="image"
                    width={200}
                    height={200}
                  />
                  <button
                    onClick={() => handleRemoveImage(image, index)}
                    className={styles.uploaderOutputImageRemove}
                    type="button"
                  >
                    <CloseIcon/>
                  </button>
                </div>
              );
            })}
        </div>
        <p className={styles.uploaderText}>
          Прикрепите фото, не более 6 в формате .png, .jpg не более
          16 мб
        </p>
        <input
          type="file"
          className={styles.uploaderInput}
          name="files"
          id="photo-gallery"
          multiple={true}
          accept="image/jpeg, image/png, image/jpg"
          ref={inputRef}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};
