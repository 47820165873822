import styles from "./index.module.scss";
import Image from "next/image";
import Link from "next/link";
import { UseMediaQuery } from "@/utils/functions/useMediaQuery";
import { ICatalogMiniItem } from "@/services/types/types";

interface ICatalogList {
  isChoice: boolean;
  catalog: ICatalogMiniItem[];
  handleClickCatalog: (id: string, code?: string) => void;
  rejectedCatalogs: string[];
  currentCatalogCode: string;
  handleClose: () => void;
}

const CatalogList = (props: ICatalogList) => {
  
  const {
    isChoice,
    rejectedCatalogs,
    catalog,
    handleClickCatalog,
    currentCatalogCode = '',
    handleClose
  } = props;
  
  const isMobile = UseMediaQuery("(max-width: 1024px)");
  
  return (
    <div className={`${styles.list} ${isChoice ? styles.hidden : ''}`}>
      {catalog.map((item, index: number) => {
        return (
          <div
            key={`${index}_Catalog_${item.id}`}
            className={`${styles.catalog} ${currentCatalogCode === item.code ? styles.active : ''}`}
            onMouseEnter={() => {
              if (isMobile) return;
              else handleClickCatalog(item.id, item.code);
            }}
            onClick={() => {
              if (!isMobile) return;
              else handleClickCatalog(item.id, item.code);
            }}
          >
            {isMobile ? (
              <Image
                src={`https://ohotaktiv.ru${item.catalog_dickpic || item.picture}`}
                alt={`Изображение каталога ${item.name}`}
                width={200}
                height={200}
                className={styles.catalog_image}
              />
            ) : null}
            {isMobile && rejectedCatalogs.includes(item.code) ? null : (
              <Link
                href={`/catalog/${item.code}`}
                className={styles.catalog_name}
                prefetch={false}
                onClick={handleClose}
              >
                {item.name}
              </Link>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default CatalogList;