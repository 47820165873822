"use client";

import { FC, useState } from "react";
import styles from "./remington-header.module.scss";
import { Squeeze as Hamburger } from "hamburger-react";
import classNames from "classnames";
import Link from "next/link";

export const RemingtonHeader: FC = () => {
  const [isOpenNav, setIsOpenNav] = useState(false);
  const headerListClassnames = classNames({
    [styles.headerListActive]: isOpenNav,
    [styles.headerList]: true,
  });
  const handleLinkClick = () => setIsOpenNav(false);
  return (
    <header className={styles.header}>
      <div className="container">
        <nav className={styles.headerNav}>
          <ul className={headerListClassnames}>
            <li className={styles.headerItem}>
              <Link
                prefetch={false}
                onClick={handleLinkClick}
                href="#clothes"
                className={styles.headerLink}
              >
                Одежда
              </Link>
            </li>
            <li className={styles.headerItem}>
              <Link
                prefetch={false}
                onClick={handleLinkClick}
                href="#shoes"
                className={styles.headerLink}
              >
                Обувь
              </Link>
            </li>
            <li className={styles.headerItem}>
              <Link
                prefetch={false}
                onClick={handleLinkClick}
                href="#equip"
                className={styles.headerLink}
              >
                Экипировка
              </Link>
            </li>
            <li className={styles.headerItem}>
              <Link
                prefetch={false}
                onClick={handleLinkClick}
                href="#optics"
                className={styles.headerLink}
              >
                Оптика
              </Link>
            </li>
            <li className={styles.headerItem}>
              <Link
                prefetch={false}
                onClick={handleLinkClick}
                href="#tourism"
                className={styles.headerLink}
              >
                Туристические товары
              </Link>
            </li>
            <li className={styles.headerItem}>
              <Link
                prefetch={false}
                onClick={handleLinkClick}
                href="#sportshoot"
                className={styles.headerLink}
              >
                Спортивная стрельба
              </Link>
            </li>
            <li className={styles.headerItem}>
              <Link
                prefetch={false}
                onClick={handleLinkClick}
                href="#crossbow"
                className={styles.headerLink}
              >
                Арбалеты
              </Link>
            </li>
            <li className={styles.headerItem}>
              <Link
                prefetch={false}
                onClick={handleLinkClick}
                href="#zip"
                className={styles.headerLink}
              >
                Уход за оружием
              </Link>
            </li>
          </ul>
        </nav>
        <div className={styles.burger}>
          <Hamburger
            size={20}
            color={"white"}
            toggled={isOpenNav}
            toggle={setIsOpenNav}
          />
        </div>
      </div>
    </header>
  );
};
