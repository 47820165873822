"use client";

import { FC } from "react";
import { Banners } from "./banners/banners";
import styles from "./index-component.module.scss";
import { Categories } from "./categories/categories";
import { Season } from "./season/season";
import { Viewed } from "../viewed/viewed";
import Link from "next/link";
import ComissionBanner from "../../assets/images/comission-banner-index.webp";
import ComissionBannerMob from "../../assets/images/comission-banner-mob-index.webp";
import { Sales } from "./sales/sales";
import { BrandsPopular } from "../brands-popular/brands-popular";
import { Blog } from "./blog/blog";
import { About } from "./about/about";
import { Hits } from "./hits/hits";
import { Picture } from "../picture/picture";
import {
  IAllStories,
  IBlogItem,
  IBrands,
  ICardMini,
  ICategory,
} from "@/services/types/types";
import { SwiperCardList } from "../swiper-card-list/swiper-card-list";
import { Stories } from "../stories/stories";
import { SwiperLinks } from "../swiper-links/swiper-links";
import { PersonalRecommendedComponent } from "../personal-recommended-component/personal-recommended-component";

interface IIndexComponent {
  banners: any;
  hits: Array<ICardMini>;
  recommended: Array<ICardMini>;
  categories: Array<ICategory>;
  season: any;
  newItems: Array<ICardMini>;
  sales: any;
  brands: Array<IBrands>;
  blogs: Array<IBlogItem>;
  weekendHits?: any;
  futureSales?: any;
  stories: IAllStories[];
}

export const IndexComponent: FC<IIndexComponent> = (props) => {
  const {
    banners,
    hits,
    recommended,
    categories,
    season,
    newItems,
    sales,
    brands,
    blogs,
    weekendHits,
    stories,
    futureSales,
  } = props;
  return (
    <main className={styles.main} id="main">
      <Banners banners={banners}/>
      <Stories stories={stories}/>
      <div className="container">
        {/* <FutureSales futureSales={futureSales} /> */}
        <Hits hits={weekendHits}/>
        <section>
          <SwiperLinks/>
        </section>
        <section className={styles.categories}>
          <h2 className={styles.title}>Популярные категории</h2>
          <Categories categories={categories}/>
        </section>
        {/* <UltraPopupVideo /> */}
        <SwiperCardList
          isVisibleTitle
          hasLink={false}
          title={"Хиты продаж"}
          items={hits}
        />
        <section className={styles.sales}>
          <h2 className={styles.title}>
            <Link prefetch={false} href={`/sales`}>Акции</Link>
            <Link prefetch={false} className={styles.titleLink} href={`/sales`}>
              смотреть
            </Link>
          </h2>
          <Sales sales={sales}/>
        </section>
        <SwiperCardList
          isVisibleTitle
          hasLink
          title={"Новинки"}
          items={newItems}
          link={"/new"}
        />
        <section className={styles.season}>
          <h2 className={styles.title}>Готовимся к сезону</h2>
          <Season season={season}/>
        </section>
        <SwiperCardList
          isVisibleTitle
          hasLink={false}
          title={"Рекомендуем"}
          items={recommended}
        />
        <section className={styles.nested}>
          <h2 className={"visually-hidden"}>Продавай оружие с ОхотАктив</h2>
          <Link prefetch={false} href={"/catalog/comission"}>
            <Picture
              className={styles.nestedBanner}
              alt={"Продавай оружие с ОхотАктив"}
              width={1450}
              height={154}
              desktop={ComissionBanner.src}
              mobile={ComissionBannerMob.src}
            />
          </Link>
        </section>
        <PersonalRecommendedComponent/>
        <BrandsPopular brands={brands}/>
        <section className={styles.blog}>
          <h2 className={styles.title}>
            <Link prefetch={false} href={`/blog`}>Блог</Link>
            <Link prefetch={false} className={styles.titleLink} href={`/blog`}>
              смотреть
            </Link>
          </h2>
          <Blog blogs={blogs}/>
        </section>
        <Viewed/>
        <About/>
      </div>
    </main>
  );
};
