import(/* webpackMode: "eager" */ "/var/www/ohotaktiv/ohotaktiv.ru/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/var/www/ohotaktiv/ohotaktiv.ru/node_modules/nextjs-toploader/dist/index.js");
import(/* webpackMode: "eager" */ "/var/www/ohotaktiv/ohotaktiv.ru/node_modules/react-toastify/dist/react-toastify.esm.mjs");
import(/* webpackMode: "eager" */ "/var/www/ohotaktiv/ohotaktiv.ru/src/components/analytics/metrika.tsx");
import(/* webpackMode: "eager" */ "/var/www/ohotaktiv/ohotaktiv.ru/src/components/arrow-top/arrow-top.tsx");
import(/* webpackMode: "eager" */ "/var/www/ohotaktiv/ohotaktiv.ru/src/components/notification/notification.tsx");
import(/* webpackMode: "eager" */ "/var/www/ohotaktiv/ohotaktiv.ru/src/components/remington-component/remington-header/remington-header.tsx");
import(/* webpackMode: "eager" */ "/var/www/ohotaktiv/ohotaktiv.ru/src/components/toast/toast.module.scss");
import(/* webpackMode: "eager" */ "/var/www/ohotaktiv/ohotaktiv.ru/src/services/providers/provider.tsx");
import(/* webpackMode: "eager" */ "/var/www/ohotaktiv/ohotaktiv.ru/src/shared/components/CookiePopup/index.tsx");
import(/* webpackMode: "eager" */ "/var/www/ohotaktiv/ohotaktiv.ru/src/utils/functions/request-client.ts")