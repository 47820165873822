"use client";

import classNames from "classnames";
import styles from "./accordion-footer.module.scss";
import { FC, useEffect, useState } from "react";
import Link from "next/link";
import {
  DzenIcon,
  OkIcon,
  RutubeIcon,
  TgIcon,
  VkIcon,
  YoutubeIcon,
} from "@/components/icon";
import {
  ControlledAccordion,
  useAccordionProvider,
} from "@szhsin/react-accordion";
import { AccordionItem } from "./accordion-item/accordion-item";

interface IAccordionFooter {}
interface IWindowSize {
  width: number;
  height: number;
}
export const AccordionFooter: FC<IAccordionFooter> = () => {
  const accordionItemWorkClassnames = classNames(
    `${styles.accordionItem} ${styles.work}`
  );
  const providerValue = useAccordionProvider({
    allowMultiple: true,
    transition: true,
    transitionTimeout: 250,
  });
  const { toggleAll } = providerValue;
  const [windowSize, setWindowSize] = useState<IWindowSize>({
    width: typeof window !== "undefined" ? window.innerWidth : 0,
    height: typeof window !== "undefined" ? window.innerHeight : 0,
  });
  const handleResize = () =>
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  useEffect(() => {
    windowSize.width > 1023 ? toggleAll(true) : toggleAll(false);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowSize]);
  return (
    <ControlledAccordion
      className={styles.accordion}
      providerValue={providerValue}
      itemScope
      itemType="http://schema.org/SiteNavigationElement"
    >
      <AccordionItem header="Покупателям" initialEntered>
        <ul
          className={styles.accordionList}
          itemProp="about"
          itemScope
          itemType="http://schema.org/ItemList"
        >
          <li
            className={styles.accordionItem}
            itemProp="itemListElement"
            itemScope
            itemType="http://schema.org/ItemList"
          >
            <Link
              prefetch={false}
              href="/license/"
              className={styles.accordionLink}
              itemProp="url"
            >
              Правила лицензии
            </Link>
            <meta itemProp="name" content="Правила лицензии" />
          </li>
          <li
            className={styles.accordionItem}
            itemProp="itemListElement"
            itemScope
            itemType="http://schema.org/ItemList"
          >
            <Link
              prefetch={false}
              href="/pay/"
              className={styles.accordionLink}
              itemProp="url"
            >
              Оплата
            </Link>
            <meta itemProp="name" content="Оплата" />
          </li>
          <li
            className={styles.accordionItem}
            itemProp="itemListElement"
            itemScope
            itemType="http://schema.org/ItemList"
          >
            <Link
              prefetch={false}
              href="/delivery/"
              className={styles.accordionLink}
              itemProp="url"
            >
              Доставка
            </Link>
            <meta itemProp="name" content="Доставка" />
          </li>
          <li
            className={styles.accordionItem}
            itemProp="itemListElement"
            itemScope
            itemType="http://schema.org/ItemList"
          >
            <Link
              prefetch={false}
              href="/faq/"
              className={styles.accordionLink}
              itemProp="url"
            >
              FAQ
            </Link>
          </li>
          <li
            className={styles.accordionItem}
            itemProp="itemListElement"
            itemScope
            itemType="http://schema.org/ItemList"
          >
            <Link
              prefetch={false}
              href="/return/"
              className={styles.accordionLink}
              itemProp="url"
            >
              Обмен и возврат
            </Link>
            <meta itemProp="name" content="Обмен и возврат" />
          </li>
          <li
            className={styles.accordionItem}
            itemProp="itemListElement"
            itemScope
            itemType="http://schema.org/ItemList"
          >
            <Link
              prefetch={false}
              href="/catalog/"
              className={styles.accordionLink}
              itemProp="url"
            >
              Каталог
            </Link>
            <meta itemProp="name" content="Каталог" />
          </li>
          <li
            className={styles.accordionItem}
            itemProp="itemListElement"
            itemScope
            itemType="http://schema.org/ItemList"
          >
            <Link
              prefetch={false}
              href="/license-department/"
              className={styles.accordionLink}
              itemProp="url"
            >
              Стрелковые клубы и ЛРО
            </Link>
            <meta itemProp="name" content="Обмен и возврат" />
          </li>
        </ul>
      </AccordionItem>
      <AccordionItem header="О компании" initialEntered>
        <ul
          className={styles.accordionList}
          itemProp="about"
          itemScope
          itemType="http://schema.org/ItemList"
        >
          <li
            className={styles.accordionItem}
            itemProp="itemListElement"
            itemScope
            itemType="http://schema.org/ItemList"
          >
            <Link
              prefetch={false}
              href="/contacts/"
              className={styles.accordionLink}
              itemProp="url"
            >
              Контакты
            </Link>
            <meta itemProp="name" content="Контакты" />
          </li>
          <li
            className={styles.accordionItem}
            itemProp="itemListElement"
            itemScope
            itemType="http://schema.org/ItemList"
          >
            <Link
              prefetch={false}
              href="/vacancy/"
              className={styles.accordionLink}
              itemProp="url"
            >
              Вакансии
            </Link>
            <meta itemProp="name" content="Вакансии" />
          </li>
          <li
            className={styles.accordionItem}
            itemProp="itemListElement"
            itemScope
            itemType="http://schema.org/ItemList"
          >
            <Link
              prefetch={false}
              href="/brands/"
              className={styles.accordionLink}
              itemProp="url"
            >
              Бренды
            </Link>
            <meta itemProp="name" content="Бренды" />
          </li>
          <li
            className={styles.accordionItem}
            itemProp="itemListElement"
            itemScope
            itemType="http://schema.org/ItemList"
          >
            <Link
              prefetch={false}
              href="/about/"
              className={styles.accordionLink}
              itemProp="url"
            >
              О компании
            </Link>
            <meta itemProp="name" content="О компании" />
          </li>
          <li
            className={styles.accordionItem}
            itemProp="itemListElement"
            itemScope
            itemType="http://schema.org/ItemList"
          >
            <Link
              prefetch={false}
              href="/policy/"
              className={styles.accordionLink}
              itemProp="url"
            >
              Политика конф.
            </Link>
            <meta itemProp="name" content="Политика конфиденциальности" />
          </li>
          <li
            className={styles.accordionItem}
            itemProp="itemListElement"
            itemScope
            itemType="http://schema.org/ItemList"
          >
            <Link
              prefetch={false}
              href="/rules-for-posting-reviews/"
              className={styles.accordionLink}
              itemProp="url"
            >
              Правила размещения отзывов
            </Link>
            <meta itemProp="name" content="Правила размещения отзывов" />
          </li>
        </ul>
      </AccordionItem>
      <div>
        <AccordionItem header="Контакты" initialEntered>
          <ul className={styles.accordionList}>
            <li className={styles.accordionItem}>
              <Link
                prefetch={false}
                href="tel:88007008256"
                className={styles.accordionLink}
                onClick={() => ym(42989679, "reachGoal", "clickOnTel")}
              >
                8 800 700-82-56
              </Link>
            </li>
            <li className={styles.accordionItem}>
              <Link
                prefetch={false}
                href="mailto:info@ohotaktiv.ru"
                className={styles.accordionLink}
              >
                info@ohotaktiv.ru
              </Link>
            </li>
            <li className={accordionItemWorkClassnames}>
              Ежедневно с 7:00 - 22:00
            </li>
          </ul>
        </AccordionItem>
        <ul className={styles.accordionSocialList}>
          <li className={styles.accordionSocialItem}>
            <Link
              prefetch={false}
              href="https://vk.com/ohotaktiv"
              className={styles.accordionSocialLink}
              target="_blank"
              rel="noopener nofollow"
              title="Подписаться на Вконтакте"
            >
              <VkIcon />
            </Link>
          </li>
          <li className={styles.accordionSocialItem}>
            <Link
              prefetch={false}
              href="https://t.me/ohotaktiv_official"
              className={styles.accordionSocialLink}
              target="_blank"
              rel="noopener nofollow"
              title="Подписаться на Телеграм"
            >
              <TgIcon />
            </Link>
          </li>
          <li className={styles.accordionSocialItem}>
            <Link
              prefetch={false}
              href="https://zen.yandex.ru/id/61d959165d11a84299ef6cec"
              className={styles.accordionSocialLink}
              target="_blank"
              rel="noopener nofollow"
              title="Подписаться на Яндекс.Дзен"
            >
              <DzenIcon />
            </Link>
          </li>
          <li className={styles.accordionSocialItem}>
            <Link
              prefetch={false}
              href="https://ok.ru/ohotaktiv"
              className={styles.accordionSocialLink}
              target="_blank"
              rel="noopener nofollow"
              title="Подписаться на Одноклассники"
            >
              <OkIcon />
            </Link>
          </li>
          <li className={styles.accordionSocialItem}>
            <Link
              prefetch={false}
              href="https://www.youtube.com/channel/UCbeXH1XWDsSVL63-d0E43pw"
              className={styles.accordionSocialLink}
              target="_blank"
              rel="noopener nofollow"
              title="Подписаться на Ютуб"
            >
              <YoutubeIcon />
            </Link>
          </li>
          <li className={styles.accordionSocialItem}>
            <Link
              prefetch={false}
              href="https://rutube.ru/channel/24618421/"
              className={styles.accordionSocialLink}
              target="_blank"
              rel="noopener nofollow"
              title="Подписаться на Рутуб"
            >
              <RutubeIcon />
            </Link>
          </li>
        </ul>
      </div>
    </ControlledAccordion>
  );
};
