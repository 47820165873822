"use client";
import { FC, useRef } from "react";
import styles from "./shop-component.module.scss";
import { TagIcon } from "../icon";
import Link from "next/link";
import replaceSymbolsTel from "@/utils/functions/replace-symbols-tel";

interface IShop {
  address: string;
  gps_n: string;
  gps_s: string;
  phone: string;
  schedule: string;
  schedule_eng: string;
  value: string;
  id: number;
  quantity?: string;
}

interface IShopComponent {
  shop: IShop;
  openBalloon?: (b: number[]) => void;
  showInfo?: boolean;
  isPreview?: boolean;
  showPhone?: boolean;
}

export const ShopComponent: FC<IShopComponent> = (props) => {
  const {
    shop,
    openBalloon = () => {},
    showInfo = true,
    isPreview = false,
    showPhone = true
  } = props;
  
  const refTel = useRef<HTMLAnchorElement>(null);
  
  const handleButtonClick = () => {
    ym(42989679, "reachGoal", "aboutStore");
  };
  
  return (
    <div
      className={styles.store}
      onClick={() => openBalloon([Number(shop.gps_s), Number(shop.gps_n)])}
      itemProp="address"
      itemScope
      itemType="https://schema.org/LocalBusiness"
    >
      <meta itemProp="name" content={"ОхотАктив"}/>
      <meta itemProp="telephone" content={shop.phone}/>
      <meta itemProp="openingHours" content={shop.schedule_eng}/>
      <TagIcon className={styles.icon}/>
      <div
        className={styles.storeInfo}
        itemProp="address"
        itemScope
        itemType="https://schema.org/PostalAddress"
      >
        <meta itemProp="streetAddress" content={shop.address}/>
        <address className={styles.address}>
          {shop.address}
          {isPreview && showPhone ? <span>Телефон: {shop.phone} </span> : null}
          <span> Время работы: {shop.schedule} </span>
        </address>
        {shop.phone && showInfo && !isPreview && (
          <>
            <p className={styles.phone}>Телефон:</p>
            <a
              href={`tel:${replaceSymbolsTel(shop.phone)}`}
              className={styles.link}
              ref={refTel}
              itemProp="telephone"
            >
              {shop.phone}
            </a>
          </>
        )}
        {isPreview ? (
          <p className={styles.quantity}>В наличии {shop.quantity} шт.</p>
        ) : null}
        {!isPreview ? showInfo ? (
          <Link
            prefetch={false}
            onClick={handleButtonClick}
            href={`/shops/${shop.value}/`}
            className={styles.shop_link}
          >
            Подробнее о магазине
          </Link>
        ) : (
          <Link
            prefetch={false}
            onClick={handleButtonClick}
            target="_blank"
            href={`/shops/${shop.value}/`}
            className={styles.shop_link}
            rel="noopener"
          >
            Подробнее о магазине a?
          </Link>
        ) : null}
      </div>
    </div>
  );
};
