"use client";
import styles from "./stories.module.scss";
import { FC, useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "./swiper.scss";
import { StoriesPopup } from "../popups/stories-popup/stories-popup";
import Image from "next/image";
import { StoriesMobilePopup } from "../popups/stories-mobile-popup/stories-mobile-popup";
import { useWindowSize } from "@/services/hooks/useWindowSize";
import { IAllStories } from "@/services/types/types";
import { getStories } from "@/utils/api/getStories";
import { useAppSelector } from "@/services/redux/hooks";
import { getStoriesViewedList } from "@/services/redux/features/storiesSlice";
import { googleViewStories } from "@/utils/api/google-analytic";
import { UseMediaQuery } from "@/utils/functions/useMediaQuery";
import StoriesElem from "@/components/stories/StoriesElem";
import { SkeletonLoader } from "@/components/skeleton-loader/skeleton-loader";

interface IStories {
  stories: IAllStories[];
}

export const Stories: FC<IStories> = (props) => {
  const {stories} = props;
  
  const storiesRef = useRef(null);
  const [showContentStories, setShowContentStories] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [focusedElem, setFocusedElem] = useState(0);
  const [windowWidth] = useWindowSize();
  const isMobile = UseMediaQuery("(max-width: 767px)");
  const storiesChecked = useAppSelector(getStoriesViewedList);
  
  const focusStory = (elemIndex: number) => {
    setShowContentStories(true);
    setFocusedElem(elemIndex);
    ym(42989679, "reachGoal", "viewStories");
    googleViewStories();
    document.body.style.overflow = "hidden";
  };
  
  const close = () => {
    setShowContentStories(false);
    document.body.style.overflow = "auto";
  };
  
  if(!stories || !stories.length) return null;
  
  return (
    <section className={styles.stories_wrap}>
      {!isLoading ? (
        <SkeletonLoader
          className={styles.skeleton}
          containerTag={"div"}
        />
      ) : null}
      {showContentStories ?
        (
          !isMobile ? (
            <StoriesPopup
              onClose={close}
              focusedElem={focusedElem}
              storiesList={stories}
            />
          ) : (
            <StoriesMobilePopup
              onClose={close}
              focusedElem={focusedElem}
              storiesList={stories}
            />
          )) : null}
      <Swiper
        className="swiper-stories--main"
        slidesPerView={"auto"}
        allowTouchMove={true}
        pagination={false}
        spaceBetween={windowWidth < 767 ? 22 : 62}
        ref={storiesRef}
        centerInsufficientSlides={windowWidth >= 767}
        loop={false}
        navigation
        onInit={() => setIsLoading(true)}
      >
        {stories.map((item, index) => {
          const checked = !!storiesChecked.find((story) => story === item.id);
          return (
            <SwiperSlide
              key={`${index + "swiper-stories" + index + "swiperSlide"}`}
              className="stories_slide"
            >
              <StoriesElem
                key={`${index + "swiper-stories" + "stories_elem"}`}
                checked={checked}
                onClick={() => focusStory(index)}
                story={item}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
};
