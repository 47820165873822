"use client";
import { Accordion } from "@szhsin/react-accordion";
import styles from "./accordion-shop.module.scss";
import { AccordionItem } from "../accordion-item/accordion-item";
import { ShopComponent } from "../shop-component/shop-component";
import InputComponent from "../input/input";
import { SearchIcon } from "../icon";
import React, { FC, FormEvent, useState } from "react";
import { IShop, IStories } from "@/services/types/types";
import { AvailablePopupStore } from "@/components/popups/available-popup/available-popup-store/available-popup-store";


// interface IStories {
//   name: string;
//   lat: string;
//   lon: string;
//   items: Array<IShop>;
// }

interface IAccordionShop {
  stores: IStories[];
  setCenter: (a: number[]) => void;
  openBalloon: (b: number[]) => void;
  isSearch?: boolean;
  style?: string;
  wareHouse?: boolean;
  isPreview?: boolean;
  showPhone?: boolean;
}

const warehouseData = {
  title: 'Удаленный склад',
  value: '',
  schedule: '',
  quantity: '',
};

export const AccordionShop: FC<IAccordionShop> = (props) => {
  const {
    stores,
    setCenter,
    openBalloon,
    isSearch = true,
    style = '',
    wareHouse = false,
    isPreview = false,
    showPhone = true
  } = props;
  const [currentShops, setCurrentShops] = useState(stores);
  const handleInput = (e: FormEvent<HTMLInputElement>) => {
    e.currentTarget.value = e.currentTarget.value.replace(
      /[^а-я, ^А-Я, a-z, A-Z '']/,
      ""
    );
    setCurrentShops(
      stores.filter((stores: IStories) =>
        stores.name.toLowerCase().includes(e.currentTarget.value.toLowerCase())
      )
    );
  };
  return (
    <div className={styles.list}>
      {isSearch ? (
        <InputComponent
          id="input-shops"
          label="Введите город"
          icon={<SearchIcon/>}
          className={styles.inputWrap}
        >
          <input
            id="input-shops"
            type="text"
            maxLength={50}
            className={styles.input}
            onChange={handleInput}
            placeholder=" "
          />
        </InputComponent>
      ) : null}
      <Accordion
        className={`${styles.accordion} ${style}`}
        transition
        transitionTimeout={250}
      >
        {wareHouse ? (
          <AvailablePopupStore
            store={warehouseData}
            warehouse
            key={`${'Удаленный склад' + "liStoreModal" + "availablePopup"}`}
          />
        ) : null}
        {currentShops.map((store: IStories, indexCurrent: number) => {
          return (
            <AccordionItem
              key={`${indexCurrent + store.name + store.lat + store.lon}`}
              header={store.name}
              contentProps={{className: styles.itemContent}}
              buttonClick={() => setCenter([Number(store.lon), Number(store.lat)])}
              className={styles.accordion_item}
            >
              <div className={styles.accordionPanel}>
                {store.items.map((shop: IShop, index: number) => {
                  return (
                    <ShopComponent
                      key={`${index + "AccordionItem" + "AccordionShop"}`}
                      shop={shop}
                      openBalloon={openBalloon}
                      isPreview={isPreview}
                      showPhone={false}
                    />
                  );
                })}
              </div>
            </AccordionItem>
          );
        })}
      </Accordion>
    </div>
  );
};
