import styles from "./enter-qr-social.module.scss";
import { FC } from "react";
import {
  AppStoreMiniIcon,
  GooglePlayMiniIcon,
  RustoreMiniIcon,
} from "@/components/icon";
import { CustomTooltip } from "@/components/custom-tooltip/custom-tooltip";

interface IEnterQrSocial {}

export const EnterQrSocial: FC<IEnterQrSocial> = () => {
  return (
    <div className={styles.list}>
      <CustomTooltip text={"Скачать в AppStore"}>
        <div className={styles.item}>
          <a
            href="https://apps.apple.com/app/%D0%BE%D1%85%D0%BE%D1%82%D0%B0%D0%BA%D1%82%D0%B8%D0%B2-%D0%BE%D1%85%D0%BE%D1%82%D0%B0-%D1%80%D1%8B%D0%B1%D0%B0%D0%BB%D0%BA%D0%B0/id6502176237"
            className={styles.link}
            target="_blank"
            rel="noopener nofollow"
          >
            <AppStoreMiniIcon />
          </a>
        </div>
      </CustomTooltip>
      <CustomTooltip text={"Скачать в Google Play"}>
        <div className={styles.item}>
          <a
            href="https://play.google.com/store/apps/details?id=comm.ohotaktiv"
            className={styles.link}
            target="_blank"
            rel="noopener nofollow"
          >
            <GooglePlayMiniIcon />
          </a>
        </div>
      </CustomTooltip>
      <CustomTooltip text={"Скачать в RuStore"}>
        <div className={styles.item}>
          <a
            href="https://rustore.ru/catalog/app/com.ohotaktiv"
            className={styles.link}
            target="_blank"
            rel="noopener nofollow"
          >
            <RustoreMiniIcon />
          </a>
        </div>
      </CustomTooltip>
    </div>
  );
};
